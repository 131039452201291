<template>
    <b-button
        class="p-0"
        variant="link"
        @click="navigateBack()"
    >
        <!-- No new line below because it causes unwanted ' ' character. -->
        <fa
            class="mr-3"
            icon="arrow-left"
        />{{ $t('buttons.back') }}
    </b-button>
</template>

<script>
export default {
    name: 'BtnBack',
    props: {
        location: {
            type: [String, Object],
            default: null,
        },
    },
    methods: {
        navigateBack() {
            if (this.location) {
                return this.$router.push(this.location);
            }

            return this.$router.go(-1);
        },
    },
};
</script>
