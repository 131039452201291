const prefix = 'fac';
const iconName = 'trash';
const width = 12;
const height = 15;
const ligatures = [];
const unicode = undefined;
const svgPathData = 'm5.99 13.35c0.24 0 0.43-0.19 0.43-0.43v-7.75c0-0.23-0.19-0.43-0.43-0.43-0.23 0-0.43 0.2-0.43 0.43v7.75c0 0.24 0.2 0.43 0.43 0.43zm-2.54-0.41c0.02 0.23 0.21 0.41 0.43 0.41h0.03c0.23-0.02 0.41-0.22 0.4-0.45 0 0-0.42-7.75-0.42-7.75-0.01-0.24-0.22-0.42-0.45-0.41-0.24 0.02-0.42 0.22-0.41 0.46 0 0 0.42 7.74 0.42 7.74zm4.64 0.41h0.03c0.22 0 0.41-0.18 0.42-0.41 0 0 0.41-7.74 0.41-7.74 0.01-0.24-0.17-0.44-0.41-0.46-0.24-0.01-0.44 0.17-0.45 0.41l-0.4 7.75c-0.02 0.23 0.17 0.44 0.4 0.45zm3.85-10.07-0.32-0.97c-0.13-0.37-0.47-0.62-0.86-0.62h-2.72v-0.89c0-0.44-0.36-0.8-0.8-0.8h-2.5c-0.44 0-0.8 0.36-0.8 0.8v0.89h-2.72c-0.39 0-0.74 0.25-0.86 0.62l-0.32 0.97c-0.08 0.22-0.04 0.46 0.1 0.65 0.13 0.19 0.35 0.3 0.59 0.3h0.33s0.75 9.22 0.75 9.22c0.06 0.68 0.64 1.22 1.32 1.22h5.87c0.68 0 1.26-0.54 1.32-1.22 0 0 0.75-9.22 0.75-9.22h0.18c0.24 0 0.46-0.11 0.59-0.3 0.14-0.19 0.18-0.43 0.1-0.65zm-7.14-2.42h2.38v0.83h-2.38v-0.83zm4.66 12.52c-0.02 0.24-0.22 0.43-0.46 0.43h-5.87c-0.24 0-0.44-0.19-0.46-0.43 0 0-0.74-9.15-0.74-9.15h8.27s-0.74 9.15-0.74 9.15zm-8.55-10 0.27-0.8c0-0.02 0.02-0.03 0.04-0.03h9.54c0.02 0 0.04 0.01 0.04 0.03l0.27 0.8h-10.16z';

const icon = [
    width,
    height,
    ligatures,
    unicode,
    svgPathData,
];

export {
    prefix,
    iconName,
    icon,
};

export default {
    prefix,
    iconName,
    icon,
};
