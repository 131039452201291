export default {
    // This file is deprecated and should not be incremented anymore.
    // Put needed translations in corresponding translation file.
    // See `conversations.js` for example.

    brand: 'MoNoA',
    users: {
        index: 'Users',
    },
    groups: {
        coaches: 'Coaches',
        groupUsers: 'Members',
        members: 'Members',
        clients: 'Clients',
        index: 'Organisations',
        show: 'Organisation',
        statistics: 'Statistics',
    },
};
