<template>
    <btn-state
        :error="request.error"
        :loading="request.loading"
        :success="request.success"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot />
    </btn-state>
</template>

<script>
import Request from '@/library/http/Request';

export default {
    name: 'BtnRequest',
    props: {
        request: {
            type: Request,
            required: true,
        },
    },
};
</script>
