import Vue from 'vue';
import * as Sentry from '@sentry/vue';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue: Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.NODE_ENV,
    });
}
