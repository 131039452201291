import {get} from 'lodash';
import store from '@/store';

export default function auth(to, from, next) {
    if (!store.state.auth.me) {
        const login = {name: 'auth.login', query: {}};

        if (get(to, 'meta.authRedirectBack')) {
            login.query.authRedirectBackTo = to.fullPath;
        }

        return next(login);
    }

    return true;
};
