import nav from '@/translations/en/nav';
import auth from '@/translations/en/auth';
import posts from '@/translations/en/posts';
import users from '@/translations/en/users';
import common from '@/translations/en/common';
import groups from '@/translations/en/groups';
import inputs from '@/translations/en/inputs';
import buttons from '@/translations/en/buttons';
import programs from '@/translations/en/programs';
import clients from '@/translations/en/clients';
import invitations from '@/translations/en/invitations';
import enumerations from '@/translations/en/enumerations';
import conversations from '@/translations/en/conversations';
import introductions from '@/translations/en/introductions';

export default {
    translation: {
        auth,
        buttons,
        common,
        conversations,
        enumerations,
        groups,
        inputs,
        invitations,
        nav,
        posts,
        programs,
        clients,
        users,
        introductions,
    },
};
