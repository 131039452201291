<template>
    <div>
        <template v-if="request.loading">
            <spinner-block />
        </template>

        <template v-else-if="request.error">
            <div class="text-center text-danger">
                <fa
                    icon="exclamation-triangle"
                    size="2x"
                />
            </div>
        </template>

        <slot v-else />
    </div>
</template>

<script>
import Request from '@/library/http/Request';

export default {
    name: 'WaitForRequest',
    props: {
        request: {
            type: Request,
            required: true,
        },
    },
};
</script>
