import moment from 'moment';

export default function install(Vue) {
    moment.defaultFormat = 'DD/MM/YYYY';
    moment.updateLocale(moment.locale(), {
        week: {
            dow: 1,
        },
    });

    Vue.use(require('vue-moment'), {moment});
}