<template>
    <b-button
        class="btn-square"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <div class="btn-square-body">
            <slot />
        </div>
    </b-button>
</template>

<script>
export default {
    name: 'BtnSquare',
};
</script>
