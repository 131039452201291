import {findFirstString} from '@/library/helpers';

export default class Errors {
    /**
     * Create a new Errors instance.
     */
    constructor() {
        this.errors = {};
    }

    /**
     * Determine if an errors exists for the given field.
     *
     * @param {string} field
     */
    has(field) {
        if (!field || !this.errors) {
            return false;
        }

        let errors = Object.assign({}, this.errors);

        if (field.indexOf('.') > -1) {
            let splitted = field.split('.');

            for (let key of splitted) {
                if (errors.hasOwnProperty(key)) {
                    errors = errors[key];
                } else {
                    return false;
                }
            }

            return true;
        }

        return this.errors.hasOwnProperty(field);
    }

    /**
     * Determine if we have any errors.
     */
    any() {
        return Object.keys(this.errors).length > 0;
    }

    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     */
    get(field) {
        if (!field || !this.errors || !this.has(field)) {
            return;
        }

        let errors = Object.assign({}, this.errors);

        if (field.indexOf('.') > -1) {
            let splitted = field.split('.');

            for (let key of splitted) {
                if (errors.hasOwnProperty(key)) {
                    errors = errors[key];
                } else {
                    return;
                }
            }

            return errors[0];
        } else {
            return findFirstString(this.errors[field]);
        }
    }

    getAsErrors(field) {
        let errors = new Errors();

        if (this.errors[field]) {
            errors.record(this.errors[field]);
        }

        return errors;
    }

    pluck(fields) {
        let field = fields.find(field => {
            return this.has(field);
        });

        return this.get(field);
    }

    /**
     * Record the new errors.
     *
     * @param {object|string} errors
     * @param {string} key
     * @param {boolean} add
     * @returns {Object}
     */
    record(errors, key, add = true) {
        let parsableErrors = {};

        let recorded = errors;

        if (key) {
            let copy = Object.assign({}, this.errors);

            if (typeof recorded === 'string') {
                recorded = [recorded];
            }

            if (add) {
                if (!copy.hasOwnProperty(key)) {
                    copy[key] = [];
                }

                copy[key] = copy[key].concat(recorded);
            } else {
                copy[key] = recorded;
            }

            parsableErrors = copy;
        } else {
            parsableErrors = recorded;
        }

        return this.errors = this.parse(parsableErrors);
    }

    /**
     * Parses errors
     *
     * @param {array} original
     * @returns {Object}
     */
    parse(original) {
        let errors = {};

        for (let key in original) {
            let error = original[key];

            if (key.indexOf('.') > -1) {
                errors = this.parseSingle(errors, key, error);
            } else {
                errors[key] = error;
            }
        }

        return errors;
    }

    /**
     * Parses a single error
     *
     * @param {Object} object
     * @param {array} path
     * @param {*} value
     * @returns {Object}
     */
    parseSingle(object, path, value) {
        if (typeof object !== 'object') {
            return object;
        }

        let splitted = path.split('.');

        const length = splitted.length;
        const lastIndex = length - 1;

        let index = -1;
        let nested = object;

        while (nested != null && ++index < length) {
            const key = splitted[index];
            let newValue = value;

            if (index != lastIndex) {
                const objValue = nested[key];
                newValue = typeof objValue === 'object' ? objValue : {};
            }

            nested[key] = newValue;
            nested = nested[key];
        }

        return object;
    }

    /**
     * Clear one or all error fields.
     *
     * @param {string|null} field
     */
    clear(field) {
        if (field) {
            delete this.errors[field];

            return;
        }

        this.errors = {};
    }
}
