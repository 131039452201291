import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import temp from '@/store/modules/temp';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth,
        temp,
    },
});

// We put the current user instance as a global Vue property,
// so every component can access it easily.
Object.defineProperty(Vue.prototype, '$me', {
    get() {
        return store.state.auth.me;
    },
});

export default store;
