const routes = [
    {
        path: 'groups',
        component: () => import('@/components/common/OwRouterView'),
        children: [
            {
                path: '',
                name: 'groups.index',
                component: () => import('@/pages/groups/Index'),
            },
            {
                path: 'create',
                name: 'groups.create',
                component: () => import('@/pages/groups/Create'),
            },
            {
                path: ':id',
                component: () => import('@/components/groups/GroupRouterView'),
                children: [
                    {
                        path: 'members',
                        name: 'groups.members',
                        component: () => import('@/pages/groups/Members'),
                    },
                    {
                        path: '',
                        component: () => import('@/components/groups/GroupSideNavRouterView'),
                        children: [
                            {
                                path: '/',
                                name: 'groups.edit',
                                component: () => import('@/pages/groups/Edit'),
                            },
                            {
                                path: 'admins',
                                name: 'groups.admins',
                                component: () => import('@/pages/groups/Admins'),
                            },
                            {
                                path: 'coaches',
                                name: 'groups.coaches',
                                component: () => import('@/pages/groups/Coaches'),
                            },
                            {
                                path: 'clients',
                                name: 'groups.clients',
                                component: () => import('@/pages/groups/Clients'),
                            },
                            {
                                path: 'statistics',
                                name: 'groups.statistics.index',
                                component: () => import('@/pages/groups/statistics/Index'),
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default routes;
