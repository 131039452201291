import Vue from 'vue';
import store from '@/store';
import Router from 'vue-router';
import {User} from '@/models/User';
import routes from '@/router/routes';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// Try to retrieve the authenticated user.
router.beforeEach(async(to, from, next) => {
    const apiToken = store.getters['auth/apiToken'];

    if (
        apiToken
        && !store.state.auth.me
    ) {
        try {
            let user = new User({id: 'me'});

            await user.fetch();

            store.commit(
                'auth/setMe',
                user,
            );
        } catch (e) {
            console.error(e);

            store.commit('auth/setApiToken', undefined);

            localStorage.removeItem('api_token');
        }
    }

    return next();
});

// Handle middleware.
router.beforeEach((to, from, next) => {
    let middleware = [];

    // Build the middleware array with middleware of its parents.
    for (let match of to.matched) {
        if (!match.meta.middleware) {
            continue;
        }

        middleware = [...middleware, ...match.meta.middleware];
    }

    // Reverse so the parent routes will first be evaluated. Remove the
    // duplicate middlewares.
    middleware = middleware.reverse().filter((item, index) => {
        return middleware.indexOf(item) === index;
    });

    // Execute each middleware.
    for (let item of middleware) {
        let result = item(to, from, next);

        // Return the result of the middleware when it is different than the
        // intended target.
        if (result !== true) {
            return result;
        }
    }

    return next();
});

export default router;
