import axios from 'axios';
import store from '@/store';
import Errors from '@/library/Errors';

export default class Request {
    /**
     * The request constructor.
     */
    constructor(options = {}) {
        this.options = options;

        this.error = false;

        this.errors = new Errors();

        this.loading = false;

        this.success = false;
    }

    /**
     * Executes the request.
     */
    async submit(options = {}) {
        const requestOptions = {
            headers: this.getHeaders(),
            ...this.options,
            ...options,
        };

        let response;

        this.loading = true;

        this.errors.clear();

        try {
            response = await axios.request(requestOptions);

            this.onSuccess(response);
        } catch (e) {
            this.onError(e.response);

            throw e.response;
        } finally {
            this.loading = false;
        }

        return response.data;
    }

    /**
     * Returns the headers according to the current state.
     */
    getHeaders() {
        let headers = {};

        const apiToken = store.getters['auth/apiToken'];

        if (apiToken) {
            headers.Authorization = `Bearer ${apiToken}`;
        }

        return headers;
    }

    /**
     * The callback that is fired once a request has been made successfully.
     */
    onSuccess(response) {
        this.success = true;

        this.error = false;
    }

    /**
     * The callback that is fired once a request has not been made successfully.
     */
    onError(response) {
        this.error = true;

        this.success = false;

        if (response.data.errors) {
            this.errors.record(response.data.errors);
        }
    }
}
