import moment from 'moment';

const defaults = {
    moment: 'MM-DD-YYYY',
    momentTimeAgo: false,
};

export default {
    methods: {
        /**
         *
         * @param {moment} value
         * @param {object} options
         * @return {string}
         */
        $format(value, options = {}) {
            /*
             * Merge formatting options with the following priority:
             * 1. method options
             * 2. component/class level options
             * 3. mixin defaults
             */
            const formatOptions = {
                ...defaults,
                ...(this.formatOptions || {}),
                ...options,
            };

            if (value instanceof moment) {
                return this._formatMoment(value, formatOptions);
            }

            // TODO: Add other types that can be formatted, like numbers etc.

            return value.toString();
        },

        /**
         * Formats the moment instance to a string.
         * @param {moment} value
         * @param {object} options
         * @return {string}
         * @private
         */
        _formatMoment(value, options) {
            if (options.momentTimeAgo) {
                return value.fromNow();
            }

            return value.format(options.moment);
        },
    },
};
