export default {
    // Common
    model: {
        name: {
            label: 'Name',
        },
        companyName: {
            label: 'Company name',
            placeholder: 'The name of your coaching group',
        },
        phone: {
            label: 'Phone',
            placeholder: '+31641234567',
        },
        email: {
            label: 'Email',
        },
        since: {
            label: 'Since',
        },
        bio: {
            label: 'Bio',
            placeholder: 'Tell us something about yourself. This information will be visible for all users.',
            placeholderNotMe: 'The bio of this user is currently empty.',
        },
        image: {
            label: 'Profile image',
        },
        newPassword: {
            label: 'New password',
        },
        currentPassword: {
            label: 'Current password',
        },
        roles: {
            label: 'Role(s)',
        },
        status: {
            label: 'Status',
        },
    },
    actions: {
        changePassword: 'Change password',
        invite: 'Invite {{type}}',
    },

    // Routes
    edit: {
        nav: 'Profile',
        title: 'My Profile',

        company: {
            nav: 'Company',
        },
    },
    members: {
        index: {
            nav: 'Clients',
            title: 'My clients',
        },
    },
    programs: {
        index: {
            nav: 'Programs',
            title: 'My programs',
        },
    },
    index: {
        title: 'Users',
    },

    add: {
        title: 'Add {{type}}',
        fields: {
            email: {
                placeholder: 'Email address(es)',
                hint: 'You can input several email addresses separated by space',
            },
            organisation: {
                unselected: 'No organisation selected',
            },
        },
        addEmails: 'Add {{emails}}',
    },
};
