<template>
    <div class="text-center text-primary">
        <fa
            icon="spinner"
            size="2x"
            spin
        />
    </div>
</template>

<script>
export default {
    name: 'SpinnerBlock',
};
</script>