export default {
    // Common
    model: {
        createdAt: {
            label: 'Uploaded',
        },
        body: {
            label: 'Description',
        },
        files: {
            label: 'Files',
        },
        privacy: {
            label: 'Privacy',
        },
        thumbnail: {
            helpText: 'Select and upload a thumbnail for the media. A good thumbnail stands out and catches the viewer\'s attention.',
        },
        title: {
            label: 'Title',
        },
        type: {
            label: 'Type',
        },
        views: {
            label: 'Views',
        },
    },
    actions: {
        uploadMedia: 'Upload media',
        selectMediaFile: 'Select media file',
    },
    messages: {
        linkToUploadMedia: 'You can upload new media in the {{0}} page.',
        linkToCreateProgram: 'You can create new program in the {{0}} tab.',

        mediaProcessing: 'The media file is still being processed and will be available soon.',
        mediaPreviousUploadFailed: 'An error occured during the previous upload. Please try to upload again.',
        mediaUploadFailed: 'An error occured while uploading the media. Please try to upload again.',
        mediaUploadSuccess: 'Media is successfully uploaded!',

        noMediasFound: 'No medias are found.',
        noPrivateMediasFound: 'No private medias are found, the public medias are automatically shared with every client.',

        uploadMediaAndAddToPrograms: 'Upload your media and add it to your programs.',
    },
    mediaDetails: 'Media details',
    mediaFile: 'Media file',

    // Routes
    index: {
        nav: 'Library',
        title: 'Library',
        titleMe: 'My Library',
    },
    create: {
        title: 'Upload media',
    },
    edit: {
        title: 'Edit media',
        deleteMedia: 'Delete media',
    },
    share: {
        title: 'Share {{postType}}',
        description: 'Your shared media will link to the free content page of MoNoA.',
    },
};
