import BtnBack from '@/components/common/buttons/BtnBack';
import BtnState from '@/components/common/buttons/BtnState';
import BtnSquare from '@/components/common/buttons/BtnSquare';
import BtnRequest from '@/components/common/buttons/BtnRequest';
import BtnResource from '@/components/common/buttons/BtnResource';
import BtnUndo from '@/components/common/buttons/BtnUndo';

import SpinnerBlock from '@/components/common/SpinnerBlock';
import WaitForRequest from '@/components/common/WaitForRequest';
import WaitForResource from '@/components/common/WaitForResource';

import VueFileAgent from 'vue-file-agent';

export default function install(Vue) {
    Vue.component('BtnBack', BtnBack);
    Vue.component('BtnState', BtnState);
    Vue.component('BtnSquare', BtnSquare);
    Vue.component('BtnRequest', BtnRequest);
    Vue.component('BtnResource', BtnResource);
    Vue.component('BtnUndo', BtnUndo);

    Vue.component('SpinnerBlock', SpinnerBlock);
    Vue.component('WaitForRequest', WaitForRequest);
    Vue.component('WaitForResource', WaitForResource);

    Vue.use(VueFileAgent);
}
