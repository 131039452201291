class Image {
    constructor(properties) {
        /**
         * {Record<string, string>|null}
         */
        this.srcSet = properties.srcSet;

        /**
         * {Number}
         */
        this.width = properties.width;

        /**
         * {Number}
         */
        this.height = properties.height;

        /**
         * {?String}
         */
        this.description = properties.description;

        /**
         * CSS class(es) applied to the image.
         *
         * {?(String|Array|Object)}
         */
        this.class = properties.class;

        /**
         * Text to be shown in place of the image.
         *
         * {?String}
         */
        this.text = properties.text;

        /**
         * A fallback image that will be used when loading of the original image fails.
         *
         * {?String}
         */
        this.fallback = properties.fallback;
    }
}

export default Image;
