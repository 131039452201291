export default {
    actions: {
        addMedia: 'Add media',
        addPrograms: 'Add programs',
        delete: 'Delete',
        remove: 'Remove',
        saveChanges: 'Save changes',
        undoChanges: 'Undo changes',
        typeMessage: 'Type message',
        invite: 'Invite',
        share: 'Share',
        next: 'Next',
    },

    createdBy: 'By: {{name}}',

    errors: {
        anErrorOccurred: 'An error occurred.',
        reloadPage: 'Reload this page to try again.',
    },

    messages: {
        confirmation: 'Are you sure?',
        confirmationChallengeQuestion: 'Type {{answer}} to confirm.',
        copiedToClipboard: 'The {{data}} has been copied to the clipboard!',
        deleteConfirmation: 'Are you sure you want to delete {{object}}?',
        deleteWarning: 'Once deleted, it will be gone forever. Please be certain.',
        leaveUnsavedConfirmation: 'You may have some unsaved changes. Are you sure you want to leave this page?',
        logoutConfirmation: 'Are you sure you want to log out?',
        removeConfirmation: 'Are you sure you want to remove {{object}}?',
    },

    words: {
        active: 'Active',
        addedAt: 'Added at',
        confirmation: 'Confirmation',
        everything: 'Everything',
        joinedAt: 'Joined at',
        media: 'Media',
        members: 'Members',
        organization: 'Organization',
        pending: 'Pending',
        search: 'Search',
        roles: 'Roles',
    },

    fields: {
        createdAt: 'Created',
    },
};
