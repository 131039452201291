const routes = [
    {
        path: 'posts',
        component: () => import('@/components/common/OwRouterView'),
        children: [
            {
                path: '',
                name: 'posts.index',
                component: () => import('@/pages/posts/Index'),
            },
            {
                path: 'create',
                name: 'posts.create',
                component: () => import('@/pages/posts/Create'),
            },
            {
                path: ':id',
                name: 'posts.edit',
                props: true,
                component: () => import('@/pages/posts/Edit'),
            },
        ],
    },
];

export default routes;
