import Vue from 'vue';
import ModalConfirmChallenge from '../ModalConfirmChallenge';

/**
 * @property {string} question
 * @property {string} answer
 * @typedef ConfirmChallenge
 */

/**
 * @property {ConfirmChallenge} challenge
 * @typedef ConfirmChallengeOptions
 */

export default {
    name: 'ConfirmChallengeMixin',
    methods: {
        /**
         * Mounts a confirmation modal with a challenge that the user must complete before being able to confirm.
         * @param {ConfirmChallengeOptions} options
         */
        $confirmChallenge(options = {}) {
            const defaults = {
                title: this.$t('common.words.confirmation'),
                message: this.$t('common.messages.confirmation'),
                challenge: {
                    question: this.$t('common.messages.confirmationChallengeQuestion', {answer: 'CONFIRM'}),
                    answer: 'CONFIRM',
                },
            };
            const Constructor = Vue.extend(ModalConfirmChallenge);
            const modal = new Constructor({
                propsData: {
                    ...defaults,
                    ...options,
                },
            });
            modal.$t = this.$t.bind(this);
            modal.$mount();
            return new Promise((resolve) => {
                const success = () => {
                    modal.$off('confirmed', success);
                    modal.$destroy();
                    resolve(true);
                };
                const cancel = () => {
                    modal.$off('cancel', cancel);
                    modal.$destroy();
                    resolve(false);
                };
                modal.$on('confirmed', success);
                modal.$on('cancel', cancel);
            });
        },
    },
};
