import {prefixRoutes} from '@/router/helpers';

export default prefixRoutes('clients', [
    {
        path: ':id',
        props: true,
        component: () => import('@/pages/clients/EditRouterView'),
        children: [
            {
                path: '',
                name: 'clients.edit',
                component: () => import('@/pages/clients/Edit'),
            },
            {
                path: 'programs',
                name: 'clients.edit.programs',
                component: () => import('@/pages/clients/EditPrograms'),
            },
            {
                path: 'media',
                name: 'clients.edit.media',
                component: () => import('@/pages/clients/EditMedia'),
            },
        ],
    },
]);
