export default {
    // Model
    fields: {
        active: 'Active members',
        membersLimit: 'Members limit',
        name: 'Name',
        slots: 'Slots',
        logo: 'Logo',
    },
    form: {
        titleFields: 'Organisation details',
        titleAdmins: 'Organisation admins',
        adminsMax: '2 maximum',
        inputs: {
            name: {
                placeholder: 'The name of the organisation',
            },
            slots: {
                placeholder: 'Maximum members of the organisation',
            },
            groupUsersCount: {
                label: 'Active members',
            },
        },
        actions: {
            create: 'Create organisation',
            edit: 'Edit organisation',
            remove: {
                title: 'Remove Organisation',
                message: 'When removing the organisation, all linked clients & coaches will no longer be able to see dedicated content.',
            },
        },
    },

    errors: {
        noGroups: 'We could not find any groups.',
    },

    // Routes
    create: {
        title: 'Create organisation',
    },
    edit: {
        nav: 'Organisation Details',
        title: 'Organisation',
    },
    groupUsers: {
        title: '{{groupName}} Members',
        confirmDelete: {
            title: 'Remove member',
            content: 'Are you sure you want to remove {{name}} from this organisation?',
        },
    },
    admins: {
        nav: 'Admins',
        title: 'Manage admins ({{amount}}/{{total}})',

        add: 'Add admin',
    },
    clients: {
        nav: 'Clients',
        title: 'Manage clients',
    },
    members: {
        nav: 'Members',
        title: 'Manage clients',
    },
    coaches: {
        manageTitle: 'Manage coaches',
        nav: 'Coaches',
        title: 'Coaches',
    },
    index: {
        title: 'Organisations',
        addOrganisation: 'Add organisation',
        confirmDelete: {
            title: 'Delete organisation',
            content: 'Are you sure you want to delete {{name}}?',
        },
    },
    invitations: {
        create: {
            title: 'Invite new members',
            emailPlaceholder: 'Input email addresses and press enter',
        },
    },
    statistics: {
        title: 'Statistics',
        all: 'All',
        filters: 'Filters',
        includedGroupUsers: 'Included members in graph',
        periods: {
            day: 'Day',
            week: 'Week',
            month: 'Month',
        },
    },
};
