import i18next from 'i18next';
import {
    BVConfigPlugin,
    ButtonGroupPlugin,
    ButtonPlugin,
    CardPlugin,
    CollapsePlugin,
    DropdownPlugin,
    FormFilePlugin,
    FormGroupPlugin,
    FormInputPlugin,
    FormPlugin,
    FormTextareaPlugin,
    InputGroupPlugin,
    LayoutPlugin,
    LinkPlugin,
    ListGroupPlugin,
    ModalPlugin,
    NavPlugin,
    NavbarPlugin,
    ProgressPlugin,
    SpinnerPlugin,
    TablePlugin,
    ToastPlugin,
    TooltipPlugin,
    TabsPlugin,
} from 'bootstrap-vue';

export default function install(Vue) {
    // Supply complete config to the BVConfigPlugin helper plugin.
    Vue.use(BVConfigPlugin, {
        BModal: {
            titleTag: 'h2',
            cancelTitle: i18next.t('buttons.cancel'),
            cancelVariant: 'light btn-min-width',
            okTitle: i18next.t('buttons.confirm'),
            okVariant: 'primary btn-min-width',
        },
        BToast: {
            toaster: 'b-toaster-bottom-right',
            autoHideDelay: 10_000,
            variant: 'dark',
            headerClass: 'py-2',
            // As designed, we will only show the header part of the toast.
            bodyClass: 'd-none',
        },
    });

    // Then use component plugins.
    Vue.use(ButtonPlugin);
    Vue.use(ButtonGroupPlugin);
    Vue.use(CardPlugin);
    Vue.use(CollapsePlugin);
    Vue.use(DropdownPlugin);
    Vue.use(FormPlugin);
    Vue.use(FormFilePlugin);
    Vue.use(FormGroupPlugin);
    Vue.use(FormInputPlugin);
    Vue.use(FormTextareaPlugin);
    Vue.use(InputGroupPlugin);
    Vue.use(LayoutPlugin);
    Vue.use(LinkPlugin);
    Vue.use(ListGroupPlugin);
    Vue.use(ModalPlugin);
    Vue.use(NavPlugin);
    Vue.use(NavbarPlugin);
    Vue.use(ProgressPlugin);
    Vue.use(SpinnerPlugin);
    Vue.use(TablePlugin);
    Vue.use(ToastPlugin);
    Vue.use(TooltipPlugin);
    Vue.use(TabsPlugin);
}
