<template>
    <div
        class="ow-image overflow-hidden"
        :class="{loaded: loaded}"
        :style="style"
    >
        <img
            v-if="src"
            :src="src"
            :alt="image.description"
            :class="imgClass"
            @error="onLoadFailed"
            @load="onLoadSuccess"
        >

        <!--
            As an alternative to <img>, we can show a <div> element with
            background color and text.
        -->
        <div
            v-else-if="image.class"
            class="d-flex align-items-center justify-content-center"
            :class="image.class"
            :title="image.description"
        >
            {{ image.text }}
        </div>
    </div>
</template>

<script>
import Image from '@/library/Image';

export default {
    name: 'OwImage',
    props: {
        image: {
            type: Image,
            required: true,
        },
        /**
         * A string key to determine which of `image.srcSet` will be used.
         */
        srcKey: {
            type: String,
            default: '210',
        },
        imgClass: {
            type: [String, Array, Object],
            required: false,
            default: undefined,
        },
    },
    data: () => ({
        error: false,
        loaded: false,
    }),
    computed: {
        /**
         * Returns the width and height CSS style.
         */
        style() {
            return {
                width: `${this.image.width}px`,
                height: `${this.image.height}px`,
            };
        },
        src() {
            if (this.error && typeof this.image.fallback === 'string') {
                return this.image.fallback;
            }
            return this._.get(this.image, `srcSet.${this.srcKey}`);
        },
    },
    methods: {
        onLoadFailed() {
            this.error = true;
        },
        onLoadSuccess() {
            this.loaded = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.ow-image {
    > * {
        width: 100%;
        height: 100%;
    }

    &.hide-until-loaded {
        overflow: hidden;
        background: $gray-100;
        border-radius: 100%;

        &:not(.loaded) {
            > * {
                visibility: hidden;
            }
        }

        &.loaded {
            background: transparent;
            border-radius: 0;
        }
    }
}
</style>
