import Vue from 'vue';

/**
 * A Vuex module that is used as a temporary place to store data.
 * Mainly used to transfer data between pages.
 */
export default {
    namespaced: true,
    mutations: {
        setItem(state, item) {
            Vue.set(state, item.key, item.value);
        },
        removeItem(state, item) {
            const key = item.key || item;

            Vue.delete(state, key);
        },
    },
};
