const prefix = 'fac';
const iconName = 'logout';
const width = 18;
const height = 21;
const ligatures = [];
const unicode = undefined;

const svgPathData = 'm11.61 10.6c0 0.58-0.47 1.05-1.05 1.05s-1.05-0.47-1.05-1.05 0.47-1.05 1.05-1.05 1.05 1.05 1.05 1.05zm-3.23-9.09 7.8 2.52c0.49 0.16 0.83 0.62 0.83 1.14v10.86c0 0.52-0.34 0.98-0.83 1.14l-7.8 2.52c-0.63 0.21-1.31-0.14-1.51-0.77-0.04-0.12-0.06-0.24-0.06-0.37v-15.9c0-0.66 0.54-1.2 1.2-1.2 0.12 0 0.25 0.02 0.37 0.06zm-4.41 2.68h-1.77c-0.66 0-1.2 0.53-1.2 1.2v9.53c0 0.66 0.54 1.2 1.2 1.2h1.1';

const icon = [
    width,
    height,
    ligatures,
    unicode,
    svgPathData,
];

export {
    prefix,
    iconName,
    icon,
};

export default {
    prefix,
    iconName,
    icon,
};
