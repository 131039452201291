<template>
    <div>
        <template v-if="resource.loading">
            <div class="text-center">
                <spinner-block />
            </div>
        </template>

        <template v-else-if="resource.fatal">
            <div class="text-center text-danger">
                <fa
                    icon="exclamation-triangle"
                    size="lg"
                />

                <div
                    v-if="showErrorMessage"
                    class="mt-2"
                >
                    <template v-if="!!resource.firstErrorMessage">
                        {{ resource.firstErrorMessage }} <br>
                    </template>

                    <template v-else>
                        {{ $t('common.errors.anErrorOccurred') }}
                    </template>

                    {{ errorText }}
                </div>
            </div>
        </template>

        <slot v-else />
    </div>
</template>

<script>
import Model from '@/models/vue-mc/Model';
import Collection from '@/models/vue-mc/Collection';

/**
 * A component to show the loading or fatal state of a resource
 * (Model / Collection).
 */
export default {
    name: 'WaitForResource',
    props: {
        errorText: {
            type: String,
            default: function() {
                return this.$t('common.errors.reloadPage');
            },
        },
        resource: {
            type: [Collection, Model],
            required: true,
        },
        showErrorMessage: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
