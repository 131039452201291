import Role from '@/library/enumerations/Role';
import {Company} from '@/models/Company';
import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import Model from './vue-mc/Model';
import Image from '@/library/Image';
import Collection from './vue-mc/Collection';

export class User extends Model {
    /**
     * Get the initials of a user from their name. Examples:
     * - Albert Pratomo -> AP
     * - Student 21 -> S21
     * - Ryan Ho -> RH
     *
     * @return {string}
     */
    get initials() {
        if (!this.name) return '';

        return this.name.split(' ').map(name => {
            const initial = !isNaN(name) && name.length <= 2 ? name : name[0];

            return initial.toUpperCase();
        }).join('');
    }

    transformations() {
        return {
            ...super.transformations(),
            company: Company,
        };
    }

    get rolesAsString() {
        return Role.rolesAsString(this.roles);
    }

    /**
     * Get the image of the model, in string or Image instance.
     *
     * @return {string|Image}
     */
    get image() {
        const rawImage = this.get('image');

        if (typeof rawImage === 'string') return rawImage;

        return new Image({
            srcSet: rawImage,
            description: this.name,
            class: 'bg-primary text-white',
            text: this.initials,
            width: 45,
            height: 45,
        });
    }

    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            name: null,
            email: null,
            image: null,
            phone: null,
            bio: null,
            password: null,
            currentPassword: null,
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'users',
        };
    }

    /**
     * Checks if the user has certain roles.
     *
     * @param  {number|Array} roles
     * @return {boolean}
     */
    hasRoles(roles) {
        let toCheck = roles;

        if (!Array.isArray(roles)) {
            toCheck = [roles];
        }

        toCheck = toCheck.filter(role => role !== undefined);

        if (!toCheck.length) {
            return false;
        }

        for (let role of toCheck) {
            if (!this.roles.includes(role)) {
                return false;
            }
        }

        return true;
    }
};

export const Users = applyAttributesMixin(class Users extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: User,
        };
    }
});
