import CardContentDefault from '@/components/common/introduction/CardContentDefault';
import Vue from 'vue';

/**
 *
 * @property {string} tour - Name of the tour
 * @property {string} identifier - Identifier of the step
 * @property {number} priority - Priority of the step, used to determine the order in which steps are displayed.
 * @property {Element} element - The element this step is bound to, set {@link targeted} to false disable a targeted step.
 * @property {boolean} targeted - When true the step will point to {@link element} on the screen, else the step is centered in the screen.
 * @property {boolean} completed - Step has been marked as complete.
 * @property {Vue} component - The step card's content component to mount.
 * @typedef TourStep
 */

export const Tour = new Vue({
    data: () => ({
        tours: {

        },
        startedTours: [],
    }),
    computed: {
        currentTourIdentifier() {
            if (this.startedTours.length >= 1) {
                return this.startedTours[0];
            }

            return undefined;
        },
        tour() {
            if (typeof this.currentTourIdentifier === 'string') {
                return this.getTour(this.currentTourIdentifier);
            }

            return {};
        },
        progress() {
            const steps = Object.values(this.tour)
                .sort((a, b) => a.priority - b.priority);
            const current = steps.findIndex(step => !step.completed) + 1;
            const total = steps.length;
            return {current, total};
        },
        step() {
            return Object.values(this.tour)
                .filter((step) => !step.completed)
                .sort((a, b) => a.priority - b.priority)[0];
        },
        finished() {
            return this.step === undefined;
        },
    },
    methods: {
        start(name) {
            this.startedTours.push(name);
        },
        next() {
            const current = this.step;

            if (current !== undefined) {
                const tour = this.tours[current.tour] || {};
                this.tours = {
                    ...(this.tours),
                    [current.tour]: {...tour, [current.identifier]: {...current, completed: true}},
                };
            }

            const next = this.step;
            if (next === undefined) {
                this.onFinished(this.currentTourIdentifier);
            } else {
                this.$emit('next', {current, next});
            }
        },
        finish() {
            const completedTour = Object.values(this.tour)
                .reduce((tour, step) => {
                    return {...tour, [step.identifier]: {...step, completed: true}};
                }, {});

            this.tours = {
                ...(this.tours),
                [this.currentTourIdentifier]: completedTour,
            };

            this.onFinished(this.currentTourIdentifier);
        },
        onFinished(name) {
            this.startedTours.splice(this.startedTours.indexOf(name), 1);
            this.$emit('finished', name);
        },
        /**
         *
         * @param name
         * @return {{ [index]: TourStep }}
         */
        getTour(name) {
            if (name in this.tours) {
                return this.tours[name];
            }
            return this.tours[name] = {};
        },

        /**
         * Insert a new tour step
         * @param {TourStep} step
         */
        insert(step) {
            const tour = this.tours[step.tour] || {};
            this.tours = {
                ...(this.tours),
                [step.tour]: {...tour, [step.identifier]: step},
            };
        },

        /**
         * Remove a tour step.
         * @param {TourStep} step
         */
        remove(step) {
            const tour = this.tours[step.tour] || {};
            const newTour = Object.assign({}, tour);
            delete newTour[step.identifier];
            this.tours = {
                ...(this.tours),
                [step.tour]: newTour,
            };
        },
    },
});

/**
 *
 * @param el
 * @param step
 * @return {TourStep}
 */
function applyDefaults(el, step) {
    const defaults = {
        priority: Number.MAX_VALUE,
        targeted: true,
        completed: false, // Would be nice to save and fetch the completed state of a step from local storage.
        component: CardContentDefault,
    };

    return {...defaults, ...step, element: el};
}

/**
 *
 * @param el
 * @param binding
 * @return {TourStep[]}
 */
function getSteps(el, binding) {
    if (Array.isArray(binding.value)) {
        return binding.value.map(step => applyDefaults(el, step));
    }

    return [applyDefaults(el, binding.value)];
}

export const TourDirective = {
    inserted(el, binding) {
        for (const step of getSteps(el, binding)) {
            Tour.insert(step);
        }
    },
    unbind(el, binding) {
        for (const step of getSteps(el, binding)) {
            Tour.remove(step);
        }
    },
};
