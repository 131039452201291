import Enumeration from './Enumeration';

export default class Role extends Enumeration {
    static CLIENT = 0;
    static ADMIN = 1;
    static COACH = 2;
    static ANALYST = 3;

    static DEFAULT_SUFFIX = 'singular';

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        return 'role';
    }

    /**
     * Converts the array of roles into a string.
     * @param {number[]} roles
     */
    static rolesAsString(roles) {
        if (!roles.length) {
            // Display 'Client' when no roles are found.
            return Role.$t(Role.CLIENT);
        }

        return roles.map((role) => {
            return Role.$t(role);
        }).join(', ');
    }

    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     */
    static get enumeration() {
        return {
            ADMIN: this.ADMIN,
            COACH: this.COACH,
            ANALYST: this.ANALYST,
            CLIENT: this.CLIENT,
        };
    };
};
