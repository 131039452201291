<template>
    <btn-state
        v-bind="$attrs"
        :loading="loading"
        :error="error"
        :success="success"
        :disabled="cDisabled"
        v-on="$listeners"
    >
        <slot v-if="!stateIconsOnly || !(loading || error || success)" />
    </btn-state>
</template>

<script>
import Model from '@/models/vue-mc/Model';
import Collection from '@/models/vue-mc/Collection';

export default {
    name: 'BtnResource',
    props: {
        resource: {
            type: [Collection, Model],
            required: true,
        },
        disabled: {
            type: Boolean,
            default: undefined,
        },
        /**
         * Indicates if this button should show loader when deleting or saving (default).
         */
        toDelete: {
            type: Boolean,
            default: false,
        },
        /**
         * If true, when loading, error, or success, only the state icons will be shown
         * and slot content will be hidden.
         */
        stateIconsOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hasLoaded: false,
        };
    },
    computed: {
        loading() {
            return this.toDelete ? this.resource.deleting : this.resource.saving;
        },
        error() {
            return this.resource.hasErrors || this.resource.fatal;
        },
        success() {
            return this.hasLoaded && !this.loading && !this.error;
        },
        /**
         * If `:disabled` prop is not provided, we will disable this button
         * if the resource is not changed. The disabled state should apply
         * after the success feedback is hidden.
         */
        cDisabled() {
            if (this.disabled !== undefined) {
                return this.disabled;
            }

            return this.toDelete
                ? false
                : !this.resource.changed() && !this.success;
        },
    },
    watch: {
        'loading': function(newVal, oldVal) {
            this.hasLoaded = Boolean(oldVal === true && newVal === false);

            // Reset `hasLoaded` back to false after 1.5 second. This will also
            // reset `success` and hide the check icon.
            if (this.hasLoaded === true) {
                setTimeout(() => this.hasLoaded = false, 1500);
            }
        },
    },
};
</script>
