import {User} from '@/models/User';

export default {
    namespaced: true,
    state: {
        me: null,
        apiToken: null,
    },
    getters: {
        apiToken(state) {
            if (state.apiToken) {
                return state.apiToken;
            }

            return state.apiToken = localStorage.getItem('api_token');
        },
    },
    mutations: {
        setMe(state, user) {
            if (user) {
                state.me = user instanceof User ? user : new User(user);
            } else {
                state.me = null;
            }
        },
        setApiToken(state, apiToken) {
            state.apiToken = apiToken;

            if (apiToken) {
                localStorage.setItem('api_token', apiToken);
            } else {
                localStorage.removeItem('api_token');
            }
        },
    },
    actions: {
        login({commit}, {user, apiToken}) {
            commit('setMe', user);

            commit('setApiToken', apiToken);
        },
        logout({commit}) {
            commit('setMe', null);

            commit('setApiToken', null);
        },
    },
};
