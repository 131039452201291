import store from '@/store';
import guest from '@/router/middleware/guest';

const routes = [
    {
        path: '/auth/logout',
        name: 'auth.logout',
        beforeEnter(to, from, next) {
            store.dispatch('auth/logout');

            next({name: 'auth.login'});
        },
    },
    {
        path: '/auth',
        meta: {
            middleware: [guest],
        },
        component: () => import('@/components/layouts/Auth'),
        children: [
            {
                path: '',
                redirect: {name: 'auth.login'},
            },
            {
                path: 'login',
                name: 'auth.login',
                component: () => import('@/components/auth/Login'),
            },
            {
                path: 'password/forgot',
                name: 'auth.password.forgot',
                component: () => import('@/components/auth/password/Forgot'),
            },
            {
                path: 'password/reset',
                name: 'auth.password.reset',
                component: () => import('@/components/auth/password/Reset'),
            },
            {
                path: 'register',
                name: 'auth.register',
                component: () => import('@/components/auth/Register'),
            },
            {
                path: '*',
                redirect: {name: 'auth.login'},
            },
        ],
    },
];

export default routes;
