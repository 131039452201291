export default {
    inputs: {
        email: {
            placeholder: 'Your email address',
        },
        name: {
            placeholder: 'Your name',
        },
        password: {
            placeholder: 'Your password',
        },
        newPassword: {
            placeholder: 'Your new password',
        },
    },
    messages: {
        userCantLoginWeb: 'This user is not allowed to log in to the web app.',
        welcome: {
            title: 'Welcome to MoNoA',
            subtitle: 'Welcome to MoNoA. The platform to train your clients online.',
        },
    },
    links: {
        terms: 'Terms & Conditions',
        privacy: 'Privacy Policy',
        help: 'Help',
    },

    login: {
        title: 'Login',
        actions: {
            forgotPassword: 'Forgot',
            login: 'Login',
        },
    },
    password: {
        forgot: {
            title: 'Forgot Password',
            actions: {
                request: 'Request',
                backToLogin: 'Back to login',
            },
            success: 'An email with instructions on how to reset your password has been sent to your email address.',
        },
        reset: {
            title: 'Reset Password',
            actions: {
                home: 'Home',
                request: 'Reset',
            },
            success: {
                canLoginWeb:'Your password has been reset and you are logged in.',
                canNotLoginWeb:'Your password has been reset. You can login to MoNoA mobile app with the new password.',
            },
            validation: {
                noToken: 'The reset password token is missing.',
            },
        },
    },
    register: {
        title: 'Register',
        messages: {
            emailShouldBePrefilled: 'The email should be prefilled.',
            invalidRegisterLink: 'This register link is invalid. The invitation data or email is missing.',
        },
        actions: {
            register: 'Register',
        },
    },
};
