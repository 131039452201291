import {prefixRoutes} from '@/router/helpers';

export default prefixRoutes('programs', [
    {
        path: ':id',
        props: true,
        component: () => import('@/pages/programs/EditRouterView'),
        children: [
            {
                path: '',
                name: 'programs.edit',
                component: () => import('@/pages/programs/Edit'),
            },
            {
                path: 'chapters',
                name: 'programs.edit.chapters',
                component: () => import('@/pages/programs/EditChapters'),
            },
            {
                path: 'clients',
                name: 'programs.edit.clients',
                component: () => import('@/pages/programs/EditClients'),
            },
        ],
    },
]);
