import store from '@/store';

/**
 * Redirects/replaces route to `users/me` when `to.params.id`
 * is the identifier of the user that is currently logged in.
 * @param to
 * @param from
 * @param next
 * @return {*}
 */
export default function user(to, from, next) {
    if (to.name === 'users.show') {
        const me = store.state.auth.me;
        if (String(me.identifier()) === String(to.params.id)) {
            return next({name: 'users.edit', replace: true});
        }
    }

    return next();
}
