const routes = [
    {
        path: 'conversations/:id?',
        name: 'conversations.index',
        props: true,
        component: () => import('@/pages/conversations/Index'),
    },
];

export default routes;
