import axios from 'axios';
import {RequestError} from 'vue-mc';

const errorHandler = {
    install(Vue) {
        Vue.config.errorHandler = function(error) {
            if (error instanceof RequestError) {
                // Catch 422 responses that are made by vue-mc requests.
                if (error.getResponse().getStatus() === 422) {
                    return;
                }

                // Catch axios cancel errors that are thrown when vue-mc
                // requests are cancelled.
                if (axios.isCancel(error.getError())) {
                    return;
                }
            }

            throw error;
        };
    },
};

export default errorHandler;
