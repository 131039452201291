export default {
    messages: {
        fromMonoa: '{{user}} from MoNoA',
    },
    platform: {
        welcome: `
### Welcome to MoNoA!

A few things to know as you get started:

* You can try out every feature in MoNoA during your trial and add as many clients you like. They can use it too, for free - always!
* Our team is standing by to answer all your questions. Feel free to reach out!
* At the end of your trial you can save your data and use them in your paid plan`.trim(),

        profile: `
### Finish your profile

Add a description about yourself and tell us a bit about your coaching services, so potential clients can reach out to you!

* Your information is visible to all MoNoA users
* Users can reach out to you through the app`.trim(),

        navigation: `
### Your management hub

You can use the top menu to navigate between the different management screens

* Create programs and link them to the right clients
* Add clients to your management tool
* Upload your content library
* Chat live with existing clients or get chat invites from new ones`.trim(),

        finish: {
            body: `
### That's it {{name}}!

You are ready to explore your first program, and share it with your clients. Let’s go!`.trim(),
            actions: {
                createProgram: 'Create program',
            },
        },
    },
};
