import $t from 'i18next';
import moment from 'moment';
import Request from './Request';
import Collection from './Collection';
import {Model as BaseModel} from 'vue-mc';
import {find, has, invert, isEmpty, merge} from 'lodash';

const RESERVED = invert([
    '_attributes',
    '_collections',
    '_errors',
    '_listeners',
    '_reference',
    '_registry',
    '_uid',
    'attributes',
    'collections',
    'deleting',
    'errors',
    'fatal',
    'loading',
    'memoized',
    'models',
    'saving',
]);

export default class Model extends BaseModel {
    /**
     * @returns {string|undefined} Endpoint of this model.
     */
    static get endpoint() {
        return (new this).getOption('endpoint') || undefined;
    }

    /**
     * @returns {boolean} `true` if this model has errors, `false` otherwise.
     */
    get hasErrors() {
        return !isEmpty(this.errors);
    }

    /**
     * @returns {string} First error message that this model has.
     */
    get firstErrorMessage() {
        if (this.hasErrors) {
            const firstError = find(this.errors, error => typeof error[0] === 'string');

            if (firstError) return firstError;
        };

        return '';
    }

    /**
     * Registers an attribute on this model so that it can be accessed directly
     * on the model, passing through `get` and `set`.
     *
     * @param {string} attribute
     */
    registerAttribute(attribute) {
        // Protect against unwillingly using an attribute name that already
        // exists as an internal property or method name.
        if (has(RESERVED, attribute)) {
            throw new Error(`Can't use reserved attribute name '${attribute}'`);
        }

        const descriptor = Object.getOwnPropertyDescriptor(this.constructor.prototype, attribute) || {};


        // Do not override custom getters and setters defined in the model.
        if (!descriptor.get) {
            descriptor.get = () => this.get(attribute);
        }

        if (!descriptor.set) {
            descriptor.set = (value) => this.set(attribute, value);
        }

        // Create dynamic accessors and mutations so that we can update the
        // model directly while also keeping the model attributes in sync.
        Object.defineProperty(this, attribute, descriptor);
    }

    /**
     * Mapping of model attributes to the class it should be transformed into.
     *
     * @returns {Record<string, Constructor>}
     */
    transformations() {
        return {
            createdAt: moment,
            updatedAt: moment,
        };
    }

    /**
     * Assigns all given model data to the model's attributes and reference.
     * This method is called on instantiation, fetch, and save success.
     *
     * @param {Record<string, any>} attrs
     */
    assign(attrs) {
        // Transform each given attribute into the desired class instance.
        Object.entries(this.transformations()).forEach(([key, constructor]) => {
            let attribute = attrs[key] || this.defaults()[key];

            if (attribute) {
                attribute = attribute instanceof constructor
                    ? attribute
                    : new constructor(attribute);

                // If attribute is a Moment instance in UTC mode, switch to local model.
                if (attribute instanceof moment && attribute.isUTC()) {
                    attribute.local();
                }

                // Set nested collection's route prefix to the parent model's fetch endpoint.
                if (attribute instanceof Collection) {
                    attribute.setOption('routePrefix', this.getFetchURL());
                }

                attrs[key] = attribute;
            }
        });

        super.assign(attrs);
    }

    /**
     * Overrides vue-mc's Model to use our own Request class.
     *
     * @returns {Request} A new `Request` using the given configuration.
     */
    createRequest(config) {
        return new Request(config);
    }

    /**
     * Returns the default options for this model.
     *
     * @returns {Object}
     */
    getDefaultOptions() {
        return merge(super.getDefaultOptions(), {
            patch: true,
            saveUnchanged: false,
            useFirstErrorOnly: true,

            mutateOnChange: false,
            mutateBeforeSync: true,
            mutateBeforeSave: false,

            validateOnChange: false,
            validateOnSave: true,
            validateRecursively: false,
        });
    }

    /**
     * Tries to construct our API route based on endpoint and identifier.
     *
     * @return {string|undefined} Route value by key.
     */
    getRoute(key, fallback) {
        let route;

        try {
            route = super.getRoute(key, fallback);
        } catch (error) {
            route = this.getOption('endpoint');

            if (!route) {
                throw new Error('Model endpoint is not yet defined.');
            }

            if (key !== 'create') {
                route += `/{${this.getOption('identifier')}}`;
            }
        }

        return route;
    }

    /**
     * Returns an error object of the whole model, or an error string
     * for a specific attribute.
     *
     * @param {string}  [attribute]
     * @param {string}  [locale]
     * @returns {Object|string} Validation errors on this model.
     */
    getErrors(attribute = '', locale) {
        const errors = super.getErrors();

        if (errors && attribute) {
            let message = '';
            const attributeWithLocale = typeof locale === 'string' ? `${attribute}.${locale}` : attribute;

            Object.entries(errors).forEach(([key, value]) => {
                if (key.startsWith(attribute) || key.startsWith(attributeWithLocale)) {
                    message += value + '\n';
                }
            });

            return message;
        }

        return errors;
    }

    /**
     * Called before a save request is made.
     *
     * @returns {boolean} `false` if the request should not be made.
     */
    onSave() {
        this.clearErrors();

        return super.onSave();
    }

    /**
     * Called when a fetch request failed.
     *
     * @param {Error} error
     */
    onFetchFailure(error) {
        super.onFetchFailure(error);

        this.recordErrorMessage(error);
    }

    /**
     * Called when a save request resulted in an unexpected error,
     * eg. an internal server error (500)
     *
     * @param {Error} error
     */
    onFatalSaveFailure(error) {
        super.onFatalSaveFailure(error);

        this.recordErrorMessage(error);
    }

    /**
     * Called when a save request resulted in a validation error (422).
     *
     * @param {Error} error
     */
    onSaveValidationFailure(error) {
        super.onSaveValidationFailure(error);

        this.recordErrorMessage(error);
    }

    /**
     * ========================================================================
     * Additional Methods
     * ========================================================================
     */

    /**
     * Returns the translated string for the given attribute. Typically used to
     * retrieve the attribute's label or placeholder.
     *
     * @param {string} attribute
     * @param {string} suffix
     * @returns {string}
     */
    getTranslation(attribute, suffix = 'label') {
        // The convention for the attribute's translation key:
        // {API endpoint}.model.{attribute}.{'label'|'placeholder'}
        // E.g.: 'leagues.model.name.label'
        return $t.t(`${this.constructor.endpoint}.model.${attribute}.${suffix}`);
    }

    /**
     * Returns a specific vue-router Location of this instance.
     * By default, the 'edit' Location will be returned.
     *
     * E.g.: `league.getLocation()` => {
     *     name: 'leagues.edit',
     *     params: {slug: 'league-slug'},
     * }
     *
     * @param {string} name
     * @returns {Location} vue-router Location.
     */
    getLocation(name = 'edit') {
        // The location name should start with this model's API endpoint,
        // followed by the specificly requested route name.
        let location = {
            name: `${this.getOption('endpoint')}.${name}`,
        };

        // If the requested location is not `index` and not `create` ...
        if (name != 'index' && name != 'create') {
            // add this model's identifier as location params.
            location.params = {
                [this.getOption('identifier')]: this.identifier(),
            };
        }

        return location;
    }

    /**
     * Records the general error message in the `_errors` attribute.
     *
     * @param {Error}  error
     */
    recordErrorMessage(error) {
        const message = typeof error.getResponse === 'function'
            ? error.getResponse().getErrorMessage()
            : undefined;

        if (message) {
            this.setErrors({message});
        }
    }

    /**
     * Determine if a model is the same with this model.
     *
     * @param  {Model}  model
     * @return {boolean}
     */
    isSame(model) {
        return this.constructor.name === model.constructor.name
            && this.identifier() === model.identifier();
    }
}
