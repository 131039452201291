export default {
    // Common
    programs: 'Programs',
    model: {
        title: {
            label: 'Title',
            placeholder: 'Name your program',
        },
        body: {
            label: 'Description',
            placeholder: 'Add the program description.',
        },
        color: {
            label: 'Color',
        },
        privacy: {
            label: 'Privacy',
        },
        chapters: {
            label: 'Chapters',
            body: {
                label: 'Description',
            },
        },
    },
    actions: {
        deleteProgram: 'Delete program',
    },
    messages: {
        createFirstProgram: 'Create your first program and share it with your clients.',
        noPrivateProgramsFound: 'No private programs are found, the public programs are automatically shared with every client.',

        saveChaptersSuccess: 'Chapters are successfully saved.',
    },

    // Routes
    index: {
        title: 'My Programs',
    },
    create: {
        title: 'Create program',
    },
    edit: {
        nav: 'Program details',
        title: 'Edit program',

        chapters: {
            nav: 'Chapters',
            title: 'Chapters',

            addChapter: 'Add Chapter',
            typeChapterTitle: 'Type chapter title here',
        },

        clients: {
            nav: 'Clients',
        },
    },
};
