<template>
    <router-view id="app" />
</template>

<script>
export default {
    name: 'App',
};
</script>

<style lang="scss">
@import '~@/assets/scss/app';
</style>
