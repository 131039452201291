export default {
    optional: ' (optional)',

    dropdown: {
        placeholder: 'Select',
    },
    files: {
        placeholder: 'Select a file',
        placeholderMultiple: 'Select files',
    },
    modelSelect: {
        placeholder: 'Start typing to search',
        noResults: 'No results',
    },
    roleDropdown: {
        placeholder: 'Select a role',
    },
    email: {
        label: 'Email',
        placeholder: 'Email address',
    },
    name: {
        label: 'Name',
    },
    newPassword: {
        label: 'New Password',
    },
    password: {
        label: 'Password',
    },
    slots: {
        label: 'Slots',
    },
    description: {
        label: 'Description',
        placeholder: 'Description',
    },
};
