import {Tour} from '@/library/directives/Tour';

export const TourStepMixin = {
    data: () => ({
        Tour,
    }),
    props: {
        step: {
            type: Object,
            required: true,
        },
    },
    methods: {
        next() {
            Tour.next();
        },
        finish() {
            Tour.finish();
        },
        async handle(action) {
            if (typeof action.handler === 'function') {
                await action.handler(this);
            }
        },
    },
};
