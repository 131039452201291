<template>
    <b-button
        :variant="computedVariant"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <fa
            v-if="loading"
            icon="spinner"
            spin
        />

        <fa
            v-else-if="success"
            icon="check"
        />

        <fa
            v-else-if="error"
            icon="exclamation-triangle"
        />

        <slot />
    </b-button>
</template>

<script>
export default {
    name: 'BtnState',
    props: {
        error: Boolean,
        loading: Boolean,
        success: Boolean,
        variant: {
            type: String,
            required: false,
            default: undefined,
        },
    },
    computed: {
        computedVariant() {
            if (typeof this.variant === 'string') {
                return this.variant;
            }

            if (this.success) {
                return 'success';
            }

            if (this.error) {
                return 'error';
            }

            return 'primary';
        },
    },
};
</script>
