import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import Model from './vue-mc/Model';
import Image from '@/library/Image';
import Collection from './vue-mc/Collection';

export class Company extends Model {
    /**
     * Get the image of the model, in string or Image instance.
     *
     * @return {string|Image}
     */
    get image() {
        const rawImage = this.get('image');

        if (typeof rawImage === 'string') return rawImage;

        return new Image({
            srcSet: rawImage,
            description: this.name,
            class: 'bg-primary text-white',
            text: this.name,
            width: 45,
            height: 45,
        });
    }

    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            name: '',
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'groups',
        };
    }
};

export const Companies = applyAttributesMixin(class Companies extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: Company,
        };
    }
});
