import auth from '@/router/middleware/auth';
import authRoutes from '@/router/routes/auth';
import usersRoutes from '@/router/routes/users';
import postsRoutes from '@/router/routes/posts';
import groupsRoutes from '@/router/routes/groups';
import programsRoutes from '@/router/routes/programs';
import clientsRoutes from '@/router/routes/clients';
import invitationsRoutes from '@/router/routes/invitations';
import conversationsRoutes from '@/router/routes/conversations';

const routes = [
    ...authRoutes,
    ...invitationsRoutes,
    {
        path: '/',
        component: () => import('@/components/layouts/Default'),
        meta: {
            middleware: [auth],
        },
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@/components/Index'),
            },
            ...groupsRoutes,
            ...usersRoutes,
            ...postsRoutes,
            ...programsRoutes,
            ...clientsRoutes,
            ...conversationsRoutes,
        ],
    },
];

export default routes;
