import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {
    faArrowLeft,
    faCheck,
    faExclamationTriangle,
    faPencilAlt,
    faPlus,
    faSignOutAlt,
    faSpinner,
    faSearch,
    faLongArrowAltLeft,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faGripLines,
    faTimes,
    faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebookF,
    faLinkedinIn,
    faTwitter,
    faYoutube,
    faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

import {
    faEnvelope,
    faCopy,
} from '@fortawesome/free-regular-svg-icons';

import {
    faTrash,
    faLogout,
    faArrowRight,
} from '@/library/custom-svg-icons';

export default function install(Vue) {
    Vue.component('Fa', FontAwesomeIcon);

    library.add(
        faArrowLeft,
        faCheck,
        faExclamationTriangle,
        faPencilAlt,
        faPlus,
        faSignOutAlt,
        faSpinner,
        faTrash,
        faSearch,
        faLongArrowAltLeft,
        faLongArrowAltDown,
        faLongArrowAltUp,
        faGripLines,
        faTimes,
        faCaretDown,
        faLogout,
        faArrowRight,

        faFacebookF,
        faLinkedinIn,
        faTwitter,
        faYoutube,
        faWhatsapp,

        faEnvelope,
        faCopy,
    );
}
