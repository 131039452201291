/**
 * Recursively find the first string in an object.
 *
 * @param {Object} o
 */
function findFirstString(o) {
    if(typeof o === 'string' || o instanceof String){
        return o;
    }

    return findFirstString(o[Object.keys(o)[0]]);
}

/**
 * Detains a callback for a fixed amount of time. This function is mostly used
 * to extend the time user feedback is visible.
 *
 * @param {Function} callback
 * @param timeout
 */
function detainFeedback(callback, timeout = 500) {
    setTimeout(callback, timeout);
}

/**
 * Check whether the element's top or bottom threshold has been reached or not.
 *
 * @param {Element} el
 * @param {number} offset
 * @param {Boolean} topThreshold
 * @return {Boolean}
 */
function elementThresholdReached(el, offset = 50, topThreshold = false) {
    if (!(el instanceof Element)){
        throw new Error('`el` should be an instance of Element.');
    }

    if (topThreshold) {
        return Boolean(el.scrollTop <= offset);
    }

    const bottomPosition = el.scrollTop + el.clientHeight;

    return Boolean(bottomPosition >= el.scrollHeight - offset);
}

async function writeToClipboard(data) {
    if (window.navigator.clipboard) {
        return window.navigator.clipboard.writeText(data);
    }

    const element = document.createElement('textarea');
    element.style.position = 'fixed';
    element.style.top = 0;
    element.style.left = 0;
    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    element.style.width = '2em';
    element.style.height = '2em';
    // We don't need padding, reducing the size if it does flash render.
    element.style.padding = 0;
    // Clean up any borders.
    element.style.border = 'none';
    element.style.outline = 'none';
    element.style.boxShadow = 'none';
    // Avoid flash of the white box if rendered for any reason.
    element.style.background = 'transparent';
    element.value = data;

    document.body.appendChild(element);
    element.focus();
    element.select();

    return new Promise((resolve, reject) => {
        try {
            document.execCommand('command');
            resolve();
        } catch (e) {
            reject(e);
        } finally {
            document.body.removeChild(element);
        }
    });
}

export {
    detainFeedback,
    findFirstString,
    elementThresholdReached,
    writeToClipboard,
};
