import {User} from '@/models/User';
import {prefixRoutes} from '@/router/helpers';
import user from '@/router/middleware/user';
import store from '@/store';
import users from '@/translations/en/users';

const myProfileProps = () => {
    const me = store.state.auth.me;
    if (me instanceof User) {
        return {id: me.identifier()};
    }

    return {id: undefined};
};

export default prefixRoutes('users', [
    {
        path: '',
        name: 'users.index',
        component: () => import('@/pages/users/Index'),
        props: true,
    },
    {
        path: 'me',
        component: () => import('@/components/users/UserRouterView'),
        props: myProfileProps,
        children: [
            {
                path: '',
                component: () => import('@/pages/users/EditRouterView'),
                children: [
                    {
                        path: '',
                        name: 'users.edit',
                        component: () => import('@/pages/users/Edit'),
                    },
                    {
                        path: 'company',
                        name: 'users.edit.company',
                        component: () => import('@/pages/users/EditCompany'),
                    },
                ],
            },
            {
                path: 'groups',
                name: 'users.groups.index',
                component: () => import('@/components/users/groups/Index'),
            },
            {
                path: 'programs',
                name: 'users.programs.index',
                component: () => import('@/components/users/programs/Index'),
            },
            {
                path: 'members',
                name: 'users.members.index',
                component: () => import('@/pages/users/Members'),
            },
        ],
    },
    {
        path: ':id',
        component: () => import('@/components/users/UserRouterView'),
        props: true,
        meta: {
            middleware: [user],
        },
        children: [
            {
                path: '',
                name: 'users.show',
                component: () => import('@/pages/users/Show'),
            },
        ],
    },
]);
