export default {
    // Common
    messages: {
        writeFirstMessageTo: 'Write your first message to {{0}}.',
    },

    // Routes
    index: {
        title: 'Chat',
    },
};
