import auth from '@/router/middleware/auth';

const routes = [
    {
        path: '/invitations',
        component: () => import('@/components/layouts/Auth'),
        meta: {
            middleware: [auth],
        },
        children: [
            {
                path: 'accept',
                name: 'invitations.accept',
                component: () => import('@/pages/invitations/Accept'),
                meta: {
                    authRedirectBack: true,
                },
            },
        ],
    },
];

export default routes;
