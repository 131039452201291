import i18next from 'i18next';

export default class Enumeration {
    static DEFAULT_SUFFIX = '';

    constructor(value) {
        this.value = value;
    }

    /**
     * @returns {Object} Enumeration key-value pairs of the Enum.
     * @throws  {Error} If the Enum has not define the enumeration key-value pairs.
     */
    static get enumeration() {
        throw new Error('The enumeration key-value pairs are not yet defined.');
    };

    /**
     * @returns {Array} Keys of the enumeration.
     */
    static get keys() {
        return Object.keys(this.enumeration);
    }

    /**
     * @returns {Array} Values of the enumeration.
     */
    static get values() {
        return Object.values(this.enumeration);
    }

    /**
     * Checks if this enumeration includes a value.
     * @param value
     * @return {boolean}
     */
    static includes(value) {
        return this.values.includes(value);
    }

    /**
     * @returns {Object[]} Object structures for every value in the enumeration.
     */
    static get objects() {
        return this.keys.map((k) => {
            return this.object(k);
        });
    }

    /**
     * @returns {string} Translation key of the Enum.
     */
    static get translationKey() {
        throw new Error('The translation key is not yet defined.');
    }

    /**
     * @returns {number} Value of the specified key.
     */
    static value(key) {
        return this.enumeration[key];
    }

    /**
     * @returns {string} Key of the specified value.
     */
    static key(value) {
        return this.keys.find(k => this.value(k) === value);
    }

    /**
     * @param {string|number} target
     * @returns {Object} Enum object of the specified key or value.
     */
    static object(target) {
        const key = typeof target === 'string'
            ? target
            : this.key(target);

        return {
            key,
            value: this.value(key),
            translation: this.translateKey(key),
        };
    }

    /**
     * @returns {string} Translation for the specified key or value.
     */
    static translate(keyOrValue, suffix = this.DEFAULT_SUFFIX) {
        return typeof keyOrValue === 'string'
            ? this.translateKey(keyOrValue, suffix)
            : this.translateValue(keyOrValue, suffix);
    }

    /**
     * Alias for `translate(keyOrValue)`.
     *
     * @returns {string} Translation for the specified key or value.
     */
    static $t(keyOrValue, suffix = this.DEFAULT_SUFFIX) {
        return this.translate(keyOrValue, suffix);
    }

    /**
     * @returns {string} Translation for the specified key.
     */
    static translateKey(key, suffix = this.DEFAULT_SUFFIX) {
        let translationKey = `enumerations.${this.translationKey}.${key}`;

        if (suffix) translationKey += `.${suffix}`;

        return i18next.t(translationKey);
    }

    /**
     * @returns {string} Translation for the specified value.
     */
    static translateValue(value, suffix = this.DEFAULT_SUFFIX) {
        const key = this.key(value) || value;

        return this.translateKey(key, suffix);
    }

    /**
     * @returns {Object[]}
     */
    static toDropdownItems(include = undefined) {
        return this.objects
            .filter(e => !include || include.includes(e.value))
            .map(e => this.toDropdownItem(e));
    }

    /**
     * @param {Object}
     * @returns {Object}
     */
    static toDropdownItem(enumerationObj) {
        return {
            label: enumerationObj.translation,
            value: enumerationObj.value,
        };
    }
};
