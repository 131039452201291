export default {
    // Common
    clients: 'Clients',
    actions: {
        addClients: 'Add clients',
        inviteClients: 'Invite clients',
        removeClient: 'Remove client',
    },
    messages: {
        inviteFirstClients: 'Invite your first clients and share your programs with them.',
        inviteClientsAndChat: 'Invite your clients and start your first chat.',
        clientConversationWillAppear: 'Your clients will appear here in your conversation list once they accept your invitation.',

        removeWarning: 'Remove client from your group, they will no longer see the contents you’ve shared with them.',
        removeConfirmation: 'Are you sure you want to remove {{clientName}} from your clients?',

        saveMediaSuccess: 'The client\'s media are successfully saved.',
        saveProgramsSuccess: 'The client\'s programs are successfully saved.',
    },

    // Routes
    edit: {
        nav: 'Personal details',

        programs: {
            nav: 'Programs',
        },

        media: {
            nav: 'Media',
        },
    },
};
