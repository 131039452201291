const prefix = 'fac';
const iconName = 'arrow-right';
const width = 6;
const height = 9;
const ligatures = [];
const unicode = undefined;
const svgPathData = 'm1 1 3.66 3.66s-3.66 3.67-3.66 3.67';

const icon = [
    width,
    height,
    ligatures,
    unicode,
    svgPathData,
];

export {
    prefix,
    iconName,
    icon,
};

export default {
    prefix,
    iconName,
    icon,
};
