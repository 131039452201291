<template>
    <div>
        <header class="d-flex pb-4 align-items-center position-relative">
            <div class="d-flex align-items-center align-items-start flex-wrap w-100">
                <ow-image
                    :image="image"
                    class="mr-3"
                />

                <i18next
                    path="introductions.messages.fromMonoa"
                    tag="div"
                    class="mr-3 my-3 text-muted"
                >
                    <span
                        place="user"
                        class="text-dark font-weight-bold"
                    >
                        Noa
                    </span>
                </i18next>
            </div>

            <b-button
                variant="light"
                class="btn-circle"
                @click="finish()"
            >
                <fa
                    icon="times"
                />
            </b-button>
        </header>

        <section class="pb-4">
            <slot>
                <vue-markdown
                    :key="step.identifier"
                    class="markdown"
                >
                    {{ step.markdown }}
                </vue-markdown>
            </slot>
        </section>

        <footer class="row justify-content-between align-items-center">
            <template v-if="step.actions">
                <b-col
                    v-for="(action) in step.actions"
                    :key="action.text"
                    :md="12 / step.actions.length"
                >
                    <b-button
                        v-bind="action"
                        class="w-100"
                        @click="handle(action)"
                    >
                        {{ action.text }}
                    </b-button>
                </b-col>
            </template>

            <template v-else>
                <b-col
                    md="6"
                    class="text-muted mb-3 mb-md-0"
                >
                    {{ Tour.progress.current }} of {{ Tour.progress.total }}
                </b-col>

                <b-col md="6">
                    <b-button
                        variant="primary"
                        class="w-100"
                        @click="next()"
                    >
                        {{ $t('common.actions.next') }}
                    </b-button>
                </b-col>
            </template>
        </footer>
    </div>
</template>

<script>
import {TourStepMixin} from '@/components/common/introduction/TourStepMixin';
import OwImage from '@/components/common/OwImage';
import Image from '@/library/Image';
import VueMarkdown from 'vue-markdown-v2';
import NoaProfilePicture from '@/assets/images/noa-profile-picture.svg';

export default {
    name: 'CardContentDefault',
    components: {VueMarkdown, OwImage},
    mixins: [TourStepMixin],
    data: function() {
        return {
            image: new Image({
                srcSet: {
                    210: NoaProfilePicture,
                },
                width: 50,
                height: 50,
                class: 'bg-light circle',
            }),
        };
    },
};
</script>
