<template>
    <b-btn
        v-bind="$attrs"
        :variant="variant"
        :disabled="isLoading || !resource.changed()"
        v-on="$listeners"
        @click="resource.reset()"
    >
        <slot>
            {{ $t('common.actions.undoChanges') }}
        </slot>
    </b-btn>
</template>

<script>
import Model from '@/models/vue-mc/Model';

export default {
    name: 'BtnUndo',
    props: {
        resource: {
            type: Model,
            required: true,
        },
        variant: {
            type: String,
            default: 'light',
        },
    },
    computed: {
        isLoading() {
            return this.resource.deleting || this.resource.saving;
        },
    },
};
</script>
