<template>
    <b-modal
        v-bind="$attrs"
        size="sm"
        visible
        centered
        hide-header-close
        title-tag="h2"
        :title="title"
        footer-class="justify-content-center"
        @cancel="$emit('cancel')"
    >
        <form @submit.prevent="submit">
            <div>
                {{ message }}
            </div>

            <div>
                {{ challenge.question }}

                <b-form-input
                    v-model="challengeAnswer"
                    autofocus
                />
            </div>
        </form>

        <template #modal-footer="{cancel}">
            <b-btn
                class="btn-min-width"
                :variant="cancelVariant"
                @click="cancel()"
            >
                {{ $t('buttons.cancel') }}
            </b-btn>

            <slot name="button-confirm">
                <b-btn
                    class="btn-min-width"
                    :variant="okVariant"
                    :disabled="!challengeCompleted"
                    @click="submit"
                >
                    {{ $t('buttons.confirm') }}
                </b-btn>
            </slot>
        </template>
    </b-modal>
</template>

<script>
/**
 * A modal component with a challenge the user must complete before being able to confirm an action.
 *
 * Exposes slots:
 * - default -> The content of .modal-body
 * - button-confirm -> The button that will trigger the action
 *
 * Emits events:
 * - confirmed -> When button confirm is clicked
 */
export default {
    name: 'ModalConfirmChallenge',
    props: {
        caseSensitive: {
            type: Boolean,
            default: false,
        },
        cancelVariant: {
            type: String,
            default: 'light',
        },
        challenge: {
            type: Object,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
        okVariant: {
            type: String,
            default: 'danger',
        },
        title: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        challengeAnswer: undefined,
    }),
    computed: {
        challengeCompleted() {
            let answer = this.challengeAnswer || '';

            let challenge = this.challenge.answer;

            if (!this.caseSensitive) {
                answer = answer.toLowerCase();

                challenge = challenge.toLowerCase();
            }

            return answer === challenge;
        },
    },
    methods: {
        submit() {
            if (!this.challengeCompleted) {
                return;
            }

            this.$emit('confirmed');
        },
    },
};
</script>
