import Vue from 'vue';
import Request from './Request';
import {Collection as BaseCollection} from 'vue-mc';
import {find, get, isEmpty, some, each, orderBy} from 'lodash';

/**
 * @property {number} total
 * @property {number} perPage
 * @property {number} currentPage
 * @typedef PaginationMetadata
 */

const PAGINATION_DEFAULTS = {total: 0, currentPage: 1, perPage: 15};

/**
 * @property {string} searchQuery
 * @property {number|undefined} perPage
 * @property {string} sortBy
 * @property {boolean} sortDesc
 * @property {PaginationMetadata} pagination
 */
export default class Collection extends BaseCollection {
    defaults() {
        return {
            searchQuery: '',
            perPage: undefined,
            sortBy: '',
            sortDesc: false,
            pagination: PAGINATION_DEFAULTS,
        };
    }

    get query() {
        const query = {};

        if (this.searchQuery) {
            query.search = this.searchQuery;
        }

        if (this.sortBy !== '') {
            // Set the attribute to sort on.
            query.order_by = this.sortBy;

            // Set the sorting direction.
            query.sort_by = this.sortDesc ? 'desc' : 'asc';
        }

        return query;
    }

    /**
     * @returns {boolean} `true` if this collection has errors, `false` otherwise.
     */
    get hasErrors() {
        return this.getErrors().some(errors => !isEmpty(errors));
    }

    /**
     * @returns {string} First error message that this collection has.
     */
    get firstErrorMessage() {
        if (this.hasErrors) {
            const firstError = find(this.errors, error => typeof error[0] === 'string');

            if (firstError) return firstError;
        };

        return '';
    }

    /**
     * Overrides vue-mc's Collection to use our own Request class.
     *
     * @returns {Request} A new `Request` using the given configuration.
     */
    createRequest(config) {
        return new Request(config);
    }

    /**
     * Tries to construct our API route based on endpoint of this collection's model.
     * It's possible to prefix the route with some string, just set `routePrefix` in
     * the collection's `options()`.
     *
     * @return {string|undefined} Route value by key.
     */
    getRoute(key, fallback) {
        let route;

        try {
            route = super.getRoute(key, fallback);
        } catch (error) {
            // If this part is reached, that means `routes()` do not contain specified key.
            // We'll construct the route based on the `options()`.

            route = this.getOption('endpoint') || this.model().endpoint;

            if (!route) {
                throw new Error('Collection\'s endpoint is not yet defined.');
            }

            const prefix = this.getOption('routePrefix');

            if (prefix) {
                route = `${prefix}/${route}`;
            }
        }

        return route;
    }

    /**
     * @return {Boolean} true if this collection has the model in its registry.
     * Override vue-mc to also check on the model's identifier (mostly `id`).
     */
    hasModelInRegistry(model) {
        const isDuplicate = Boolean(find(this.models, m => {
            return m.identifier() && m.identifier() === model.identifier();
        }));

        return isDuplicate || super.hasModelInRegistry(model);
    }

    /**
     * @returns {Object} Default HTTP methods.
     */
    getDefaultMethods() {
        // Override vue-mc to save collection using PATCH method.
        return {
            ...super.getDefaultMethods(),
            save: 'PATCH',
        };
    }

    /**
     * @returns {Object} Query parameters that will be appended to the `fetch` URL.
     */
    getFetchQuery() {
        const query = this.query;

        if (this.isPaginated()) {
            if (this.perPage) {
                query.per_page = this.perPage;
            }
        }

        return {
            ...super.getFetchQuery(),
            ...query,
        };
    }

    /**
     * Called when a fetch request was successful.
     *
     * @param {Object} response
     */
    onFetchSuccess(response) {
        if (this.isPaginated()) {
            // Check for the `lastPage` attribute returned in the API paginated resource.
            const lastPage = get(response, 'response.data.lastPage', null);

            const {total, currentPage, perPage} = {
                ...PAGINATION_DEFAULTS,
                ...get(response, 'response.data', {}),
            };

            this.pagination = {total, currentPage, perPage};

            // If already at the last page, set `_page` to -1 to prevent further
            // unnecessary fetching. See {@link Collection.applyPagination}.
            if (lastPage === this.getPage()) {
                Vue.set(this, '_page', -1);
            }
        }

        super.onFetchSuccess(response);
    }

    /**
     * @returns {Model[]} Models in this collection that are in a "saving" state.
     * Override vue-mc to not care about the "saving" state and always save all models.
     */
    getSavingModels() {
        return this.models;
    }

    /**
     * Set the validation errors object returned by API in each corresponding
     * model's `_errors`.
     *
     * @param  {Object}   errors
     * @param  {integer} status Response status
     */
    applyValidationErrorObject(errors) {
        // The structure of errors object from API is like this:
        // {'users.0.company.title': 'Title is required'}. We need to set each error
        // message to the corresponding model in the collection.
        each(errors, (message, key) => {
            // Split the error key using `.` as separator.
            let [prefix, index, ...attribute] = key.split('.');

            // Join the last part (attribute) with `.`, so we always have 3 parts in total.
            attribute = attribute.join('.');

            let model = get(this.models, index);

            if (model) {
                // Following the example above, this will set error for models[0]
                // with {'company.title': 'Title is required'}.
                model.setErrors({[attribute]: message});
            }
        });
    }

    /**
     * ========================================================================
     * Additional Methods
     * ========================================================================
     */

    /**
     * Returns true if any model in this collection passes the predicate check, else false.
     *
     * @param {string|function|Object} predicate
     * @return {boolean}
     *
     * @see {@link https://lodash.com/docs/#some}
     */
    some(predicate) {
        return some(this.models, predicate);
    }

    /**
     * Returns true if any model in this collection has changed.
     *
     * @return {boolean}
     */
    changed() {
        return this.some(model => !!model.changed());
    }

    /**
     * Add `index` to each model based on its order in this collection. The first
     * model will have index 0.
     */
    indexModels() {
        this.models.forEach((model, index) => {
            Vue.set(model, 'index', index);
        });
    }

    /**
     * Order the models in this collection by certain attributes.
     *
     * @param {string[]} attributes
     * @param {string[]} orders
     * @return {void}
     *
     * @see {@link https://lodash.com/docs/#orderBy}
     */
    orderBy(attributes, orders = []) {
        Vue.set(this, 'models', orderBy(this.models, attributes, orders));
    }

    /**
     * Returns a specific vue-router Location of this instance.
     * By default, the 'edit' Location will be returned.
     *
     * E.g.: `league.getLocation()` => {
     *     name: 'leagues.edit',
     *     params: {slug: 'league-slug'},
     * }
     *
     * @param {string} name
     * @returns {Location} vue-router Location.
     */
    getLocation(name = 'create') {
        const endpoint = this.getOption('endpoint') || this.getOption('model').endpoint;

        return {name: `${endpoint}.${name}`};
    }
}
