export default {
    accept: {
        title: 'Accepting invitation',
    },
    form: {
        actions: {
            create: 'Send invitations',
        },
        title: 'Personal details',
    },
    messages: {
        acceptSuccess: 'The invitation has been accepted, you will be redirected shortly.',
    },
};
