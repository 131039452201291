import store from '@/store';

export default function guest(to, from, next) {
    if (store.state.auth.me) {
        return next({
            name: 'home',
        });
    }

    return true;
};
