/**
 * Converts CamelCased string to snake_case.
 * @param {string} value
 * @return {string}
 */

const RESERVED = [
    '_attributes',
    '_collections',
    '_errors',
    '_listeners',
    '_reference',
    '_registry',
    '_uid',
    'attributes',
    'collections',
    'deleting',
    'errors',
    'fatal',
    'loading',
    'memoized',
    'models',
    'saving',
];

export function applyAttributesMixin(model) {
    const attributes = model.prototype.defaults();

    for (const [key, value] of Object.entries(attributes)) {
        registerAttribute(model.prototype, key, value);
    }

    return model;
}

export function registerAttribute(target, key) {
    if (RESERVED.includes(key)) {
        throw new Error(`Cannot register attribute for reserved key: ${key}`);
    }

    const overrides = {
        set: function(value) {
            this.set(key, value);
        },
        get: function() {
            return this.get(key);
        },
    };

    const descriptor = {
        ...overrides,
        ...(Object.getOwnPropertyDescriptor(target, key) || {}),
    };

    Object.defineProperty(target, key, descriptor);
}
