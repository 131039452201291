export default {
    role: {
        ADMIN: {
            singular: 'Admin',
            plural: 'Admins',
        },
        COACH: {
            singular: 'Coach',
            plural: 'Coaches',
        },
        ANALYST: {
            singular: 'Analyst',
            plural: 'Analysts',
        },
        USER: {
            singular: 'User',
            plural: 'Users',
        },
        CLIENT: {
            singular: 'Client',
            plural: 'Clients',
        },
    },
    postPrivacy: {
        PUBLIC:{
            label: 'Public',
            text: 'Visible to public',
            description: 'Every user has access to this item.',
        },
        RELATED_TO_CREATOR:{
            label: 'Unlisted',
            text: 'Visible to all my clients & organizations',
            description: 'Clients or organizations that are related to me have access to this item.',
        },
        RELATED_TO_POST:{
            label: 'Private',
            text: 'Visible to clients & organizations when shared privately',
            description: 'Only clients or organizations who are granted direct permission have access to this item.',
        },
    },
    postType: {
        VIDEO: 'Video',
        AUDIO: 'Audio',
        ARTICLE: 'Article',
        PROGRAM: 'Program',
        CHAPTER: 'Chapter',
        PDF: 'PDF',
        IMAGE: 'Image',
    },
};
