import {TourDirective} from '@/library/directives/Tour';
import Vue from 'vue';
import '@/library/sentry';
import {get} from 'lodash';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import VueLodash from 'vue-lodash';
import PortalVue from 'portal-vue';
import axios from '@/library/http/axios';
import VueMoment from '@/library/VueMoment';
import FontAwesome from '@/library/FontAwesome';
import BootstrapVue from '@/library/BootstrapVue';
import VueI18Next from '@/translations/VueI18Next';
import CommonComponents from '@/components/common';
import globalErrorHandler from '@/library/globalErrorHandler';
import InfiniteScroll from '@/library/directives/InfiniteScroll';
import ConfirmChallenge from '@/components/common/mixins/ConfirmChallenge';
import FormatterMixin from '@/components/mixins/FormatterMixin';

Vue.config.productionTip = false;

axios.init();

Vue.use(VueMoment);
Vue.use(PortalVue);
Vue.use(BootstrapVue);
Vue.use(FontAwesome);
Vue.use(CommonComponents);
Vue.use(VueLodash, {lodash: {get}});
Vue.use(globalErrorHandler);

Vue.mixin(FormatterMixin);

Vue.directive('infinite-scroll', InfiniteScroll);
Vue.directive('tour', TourDirective);

Vue.mixin(ConfirmChallenge);

new Vue({
    router,
    store,
    i18n: VueI18Next,
    render: (h) => h(App),
}).$mount('#app');
